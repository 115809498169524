<template>
  <div
    class="admin--urls"
  >
    <r-page-header
      class="admin--urls mb-16 mt-8"
      label="Urls"
    />
    <div>
      <r-error-message
        v-if="fetchingUrlsError"
        :errors="[fetchingUrlsError]"
        class="admin--urls--errormessage"
      />
      <v-row class="py-5">
        <v-col
          class="ml-auto"
          cols="auto"
        >
          <r-search-input
            :value="search"
            class="mr-2"
            style="width:300px"
            @change="onChangeSearch"
          />
        </v-col>
      </v-row>
      <div
        v-if="search"
        class="mb-4"
      >
        <div
          class="admin--urls--chip"
        >
          <span class="admin--urls--label">
            {{ search }}
          </span>
          <v-icon
            class="admin--urls--icon"

            @click="onRemove"
          >
            close
          </v-icon>
        </div>
      </div>
      <r-table
        :is-selectable="false"
        :columns="tableColumns"
        :loading="fetchingUrls"
        :page="page"
        :page-size="pageSize"
        :rows="urls"
        :total-rows="totalUrls"
        class="admin--urls--table"
        @change:page="({ value }) => setPage({ page: value })"
        @change:page-size="onChangePageSize"
        @change:page-size-all="onChangePageSizeAll"
        @sort:asc="({ value }) => setSort({ sort: value, sortDir: 'asc' })"
        @sort:desc="({ value }) => setSort({ sort: value, sortDir: 'desc' })"
        @change:batch-select="(data) => $emit('change:batch-select', data)"
        @change:batch-select-all="() => $emit('change:batch-select-all')"
      >
        <template v-slot:item.name="{ item }">
          <router-link
            :to="`/admin/urls/${item._id}`"
          >
            <span
              :title="item.name"
              class="admin--urls--name text-truncate"
            >
              {{ item.name }}
            </span>
          </router-link>
        </template>
        <template v-slot:item.url="{ item }">
          <r-chip :label="item.url" />
        </template>
        <template v-slot:item.clients.clientId="{ item }">
          <r-chip
            v-if="item.clients?.length"
            :label="item.clients[0].clientId.toString()"
            :title="implode(item.clients, 'clientId', 'clientId')"
          />
        </template>

        <template v-slot:item.clients.brands="{ item }">
          <r-chip
            v-if="item.clients?.[0]?.brands?.length"
            :label="item.clients[0].brands[0].name"
            :title="implode(item.clients, 'brands', 'name')"
          />
        </template>
        <template v-slot:item.clients.categories="{ item }">
          <r-chip
            v-if="item.clients?.[0]?.categories?.length"
            :label="item.clients[0].categories[0].name"
            :title="implode(item.clients, 'categories', 'name')"
          />
        </template>
        <template v-slot:item.tags="{ item }">
          <r-chip
            v-if="item?.tags?.length"
            :label="item.tags[0].name"
            :title="implode(item.tags, 'name', 'name')"
          />
        </template>
        <template v-slot:item.averageRating="{ item }">
          <r-rating
            :value="item.averageRating"
            class="admin--urls--rating"
          />
        </template>
        <template v-slot:item.numReviews="{ item }">
          {{ item.numReviews }}
        </template>
        <template v-slot:item.lastReviewed="{ item }">
          <span v-if="item.lastReviewed">{{ formatDate(item.lastReviewed) }}</span>
          <span v-else>Never</span>
        </template>
        <template v-slot:item.nextScrape="{ item }">
          <span>{{ formatUpdateSlot(item.nextScrape) }}</span>
        </template>
        <template v-slot:item.clients.created="{ item }">
          {{ oldestCreatedDate(item.clients) }}
        </template>
        <template v-slot:item.nonCanonicalUrls="{ item }">
          <r-chip
            v-if="item.nonCanonicalUrls.length"
            :label="item.nonCanonicalUrls[0].toString()"
            :title="implode(item.nonCanonicalUrls, 'nonCanonicalUrls', 'nonCanonicalUrls')"
          />
        </template>
      </r-table>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import RTable from '@/components/library/organisms/RTable'
import RErrorMessage from '@/components/library/atoms/RErrorMessage'
import RChip from '@/components/library/atoms/RChip'
import RRating from '@/components/library/atoms/RRating'
import debounce from '@/utils/debounce'
import formatDate from '@/utils/formatDate'
import dateStrToDate from '@/utils/dateStrToDate'
import RPageHeader from '@/components/library/molecules/RPageHeader.vue'
import RSearchInput from '@/components/library/molecules/RSearchInput.vue'

export default {
  name: 'Urls',
  components: {
    RSearchInput,
    RPageHeader,
    RTable,
    RErrorMessage,
    RChip,
    RRating,
  },
  data: () => ({
    variantUrlsPopupPosition: null,
    popupVariantsUrls: null,
    tableColumns: [
      {
        key: 'name',
        label: 'URL',
        hideable: true,
        sortable: true,
        width: 270,
      },
      {
        key: 'url',
        label: 'Link',
        hidden: true,
        hideable: true,
        sortable: true,
        width: 250,
      },
      {
        key: 'clients.clientId',
        label: 'Clients',
        hidden: true,
        hideable: true,
        sortable: false,
        width: 150,
      },
      {
        key: 'clients.brands',
        label: 'Brand',
        hideable: true,
        sortable: false,
        width: 80,
      },
      {
        key: 'clients.categories',
        label: 'Categories',
        hideable: true,
        sortable: false,
        width: 80,
      },
      {
        key: 'tags',
        label: 'Tags',
        hideable: true,
        sortable: false,
        width: 80,
      },
      {
        key: 'averageRating',
        label: 'Rating',
        hideable: true,
        sortable: true,
        width: 120,
      },
      {
        key: 'numReviews',
        label: 'Reviews',
        hideable: true,
        sortable: true,
        width: 70,
      },
      {
        key: 'lastReviewed',
        label: 'Last reviewed',
        hideable: true,
        sortable: true,
        width: 100,
      },
      {
        key: 'nextScrape',
        label: 'Next Scrape',
        hideable: true,
        sortable: false,
        width: 150,
      },
      {
        key: 'clients.created',
        label: 'Creation date',
        hideable: true,
        sortable: true,
        hidden: true,
        width: 100,
      },
      {
        key: 'nonCanonicalUrls',
        label: 'Variant Urls',
        hidden: true,
        sortable: false,
        hideable: true,
        width: 250,
      },
    ],
    debouncedFetchData: null,
    page: 1,
    pageSize: 50,
    search: '',
    sort: '_id',
    sortDir: 'asc',
  }),
  computed: {
    ...mapGetters('admin', ['urlsModule']),
    urls() {
      return this.urlsModule.urls
    },
    totalUrls() {
      return this.urlsModule.totalRecords
    },
    fetchingUrls() {
      return this.urlsModule.fetchingUrls
    },
    fetchingUrlsError() {
      return this.urlsModule.fetchingUrlsError
    },
  },
  watch: {
    page() {
      this.$data.debouncedFetchData()
    },
    pageSize() {
      this.$data.debouncedFetchData()
    },
    search() {
      this.$data.debouncedFetchData()
      this.page = 1
    },
    sort() {
      this.$data.debouncedFetchData()
    },
    sortDir() {
      this.$data.debouncedFetchData()
    },
  },
  mounted() {
    this.$data.debouncedFetchData = debounce(() => this.fetchData(), 10)
    this.fetchData()
  },
  beforeDestroy() {
    document.removeEventListener('mousedown', this.onClickPage)
  },
  methods: {
    ...mapActions('admin', [
      'fetchUrls',
    ]),

    fetchData() {
      const {
        page, pageSize, sort, sortDir, search,
      } = this
      let payload = {
        page, pageSize, search,
      }
      if (sort) {
        payload = { ...payload, sort, sortDir }
      }

      this.fetchUrls(payload)
    },
    onChangePageSize({ value }) {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: value })
    },
    onChangePageSizeAll() {
      this.setPage({ page: 1 })
      this.setPageSize({ pageSize: this.totalUrls })
    },
    formatDate(date) {
      return formatDate({
        $moment: this.$moment,
        date: dateStrToDate(date),
      })
    },
    onChangeSearch({ value }) {
      this.search = value
    },
    implode(items, field, directory) {
      return items.map((item) => {
        if (Array.isArray(item[field])) {
          return item[field].map((it) => it[directory])
        }
        return item[directory] || item
      }).join(',')
    },
    setPage({ page }) {
      this.page = page
    },
    setPageSize({ pageSize }) {
      this.pageSize = pageSize
    },
    onRemove() {
      this.search = ''
    },
    setSort({ sort, sortDir }) {
      if (this.sort !== sort) {
        this.sort = sort
      }
      if (this.sortDir !== sortDir) {
        this.sortDir = sortDir
      }
    },
    oldestCreatedDate(data) {
      if (!data?.length) {
        return ''
      }
      let oldestDate
      data.forEach(({ created }) => {
        if (!oldestDate || this.$moment(created) < this.$moment(oldestDate)) {
          oldestDate = created
        }
      })
      return this.formatDate(oldestDate)
    },
    formatUpdateSlot(nextScrape) {
      const { $moment } = this
      const {
        updateSlot,
        triggerScrape,
        updateEveryNumDays,
      } = nextScrape

      if (triggerScrape) {
        return 'Trigger scrape'
      }

      const now = $moment.utc()
      const startOfDay = now.clone().startOf('day')
      const minutesOfDayUTC = now.diff(startOfDay, 'minutes')

      let daysUntilNextRun = 0
      let daysSinceLastRun = 0
      let isScrapeDay = false
      if (updateEveryNumDays > 1) {
        const dayOfYear = now.dayOfYear()
        daysSinceLastRun = dayOfYear % updateEveryNumDays
        isScrapeDay = ((dayOfYear - 1) % updateEveryNumDays) > daysSinceLastRun
        daysUntilNextRun = isScrapeDay ? 0 : updateEveryNumDays - daysSinceLastRun
      }

      let remainingMinutes = 0
      if (minutesOfDayUTC < updateSlot) {
        remainingMinutes = updateSlot - minutesOfDayUTC
      } else {
        remainingMinutes = 1440 - (minutesOfDayUTC - updateSlot)
      }

      const duration = $moment.duration(remainingMinutes, 'minutes')
      const hours = Math.floor(duration.asHours())
      const minutes = duration.minutes()

      return `${daysUntilNextRun}d ${hours}h ${minutes}m`
    },
  },
}
</script>

<style scoped>

.admin--urls /deep/ .rtable--cell:first-child {
  padding-left: 16px;
}
.admin--urls--table {
  width: 100%;
}

.admin--urls--name {
  width: 238px; /* 270 minus padding */
  display: block;
}
.admin--urls--chip {
  max-width: 250px;
}
.admin--urls--icon {
  position: absolute;
  top: -7px;
  right: -6px;
  cursor: pointer;
}
.admin--urls--chip {
  display: inline-block;
  padding: 5px 10px;
  margin-top: 5px;
  margin-right: 6px;
  color: var(--r-text-color);
  background-color: var(--r-light-grey);
  border-radius: var(--r-border-radius);
  position: relative;
}
.admin--urls--label {
  line-height: 1;
  font-size: 0.92rem;
}
</style>
